import Breakpoints from './breakpoints';

export default {
  init() {
    // const contentSliders = document.querySelectorAll('.iko-content-slider');
    // const breakpoints = Breakpoints.breakpoints;
    // const desktop = breakpoints.find(breakpoint => breakpoint.name = 'desktop');
    // const html = document.querySelector('html');
    // const viewportWidth = praseInt(html.getAttribute(style).split(';--viewport-width:')[1]);

    // if (viewportWidth < desktop) {
    //   contentSliders.forEach(slider => {
    //     slider.setAttribute('data-splide', slider.getAttribute);
    //     slider.setAttribute('data-splide-per-move', 1);
    //   });
    // }
  }
}